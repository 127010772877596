<template>
  <div>
    <Modal :options="{width: '40vw', close: true}"  @close="$emit('close')">
      <div class="title">Cargar documento</div>
      <div class="body">
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Documento</label>
          <div class="col-sm-9">
            <div class="input-group mb-3">
              <div class="form-control">{{ obtener_nombre }}</div>
              <div class="input-group-append">
                <button class="btn btn-secondary" @click="$refs.seleccionar_documento.click()">Seleccionar documento</button>
              </div>
            </div>
            <input type="file" class="seleccionar_documento" name="seleccionar_documento" id="seleccionar_documento" ref="seleccionar_documento" @change="obtener_nombre_fc" />
          </div>
        </div>
        <div class="row form-group">
          <label for="etapa" class="col-form-label col-sm-3">Etapa a subir</label>
          <div class="col-sm-9">
            <select v-model="etapa" name="etapa" id="etapa" class="form-control">
              <option value="callback">CallBack</option>
              <option value="opinion">Opinión de Valor</option>
              <option value="citas">Preautorización</option>
              <option value="entrevistas">Documentación</option>
              <option value="comite">Autorización</option>
              <option value="ejercido">Ejercido</option>
              <option value="desarrollo">Desarrollo Empresarial</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="etapa" class="col-form-label col-sm-3">Etiqueta</label>
          <div class="col-sm-9">
            <select v-model="etiqueta" name="etapa" id="etapa" class="form-control">
              <option v-for="opcion in catalogo.opciones" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="descripcion" class="col-form-label col-sm-3">Descripción</label>
          <div class="col-sm-9">
            <input v-model="descripcion" type="text" name="descripcion" id="descripcion" class="form-control">
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="cargar_documento">Cargar documento</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
  import Modal from '@/components/Modal'
  import apiServicios from '@/apps/solicitudes/api/servicios'
  import apiValija from '@/apps/solicitudes/api/valija'

  export default {
    components: {
      Modal
    },
    props: {
      solicitud: {
        type: Object,
        default() {
          return {
            id: null
          }
        }
      }
    }
    ,data() {
      return {
        catalogo: {
          opciones: []
        },
        etapas: ['callback','opinion','citas','entrevistas','comite','ejercido','desarrollo'],
        archivo: null,
        etapa: 'callback',
        descripcion: null,
        etiqueta: null,
        obtener_nombre: null
      }
    }
    ,mounted() {
      this.obtener_catalogo();
    }
    ,methods: {
      async obtener_catalogo() {
        try {
          this.catalogo = (await apiValija.obtener_catalogo('tipos_archivos')).data;
          this.etiqueta = this.catalogo.opciones[0].valor;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,obtener_nombre_fc() {
        if (this.$refs.seleccionar_documento.files.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar al menos un documento','error','alert');

        this.$log.info('obtener nombre', this.$refs.seleccionar_documento.files[0].name);

        this.obtener_nombre = this.$refs.seleccionar_documento.files[0].name;
      }
      ,async cargar_documento() {
        try {
          if (this.$refs.seleccionar_documento.files.length == 0)
            return this.$helper.showMessage('Error','Debes seleccionar al menos un documento','error','alert');

          if (!this.descripcion)
            return this.$helper.showMessage('Error','Debes definir la descripción del documento','error','alert');

          let formData = new FormData();
          formData.append('documento', this.$refs.seleccionar_documento.files[0]);
          formData.append('modulo', this.etapa);
          formData.append('tag', this.etiqueta);
          formData.append('descripcion', this.descripcion);

          let res = (await apiServicios.cargar_documento(this.solicitud.id, formData)).data;
          this.$log.info('res', res);
          this.$emit('update');
          this.$helper.showMessage('Echo','Se a subido el documento correctamente','success','alert');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style>
  .seleccionar_documento {
    display: none;
  }
</style>